.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
 
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-color: #fff;
}

.App-link {
  color: #61dafb;
}

#root {
  text-align: center;
  margin: .8rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}



.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}


#header .pro-sidebar {
  height: 90vh;
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}



.rw-popup-container.rw-calendar-popup {
  min-width: 200px;
  width: auto;
  left: -11px; 
  right: -11px;
}

.table1 {
  display: flex;
}

.column1 {
  margin-bottom: 1em;
  width: 100%;
}

.row1 {
  border: 1px black solid;
  height: 3em;
  margin: auto;
  width: 100%;
}
