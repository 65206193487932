@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 80px;
  background-color: #C19A6B;
  position: relative;
}


.menu-icon {
  display: none;
}


.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 10px;
}

.nav-elements li{
  border-color: #574c4c;
  border-width: 1px;
  border-radius: 5%;
  border-style:groove;
  background-color: #fef7e5 ;
  
}
.nav-elements ul a {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  padding: 0% 3px;

}

.nav-elements ul a.active {
  background-color: #574c4c;
  color: #fcfcfc;
  font-weight: 500;
  position: relative;

}


@media (max-width: 1199px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 10px;
  }
  .nav-elements ul a {
    font-size: 1rem;
  }
  .container{
    max-width: 100%;
  }
  .logo{
    width: 3%;
  }
}

@media (max-width: 800px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #C19A6B;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}